<template>
  <div class="rep-warp">
    <div
      style="
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div>快捷回复</div>
      <el-button type="text" @click="onAdd">新增</el-button>
    </div>
    <div class="replist">
      <div
        @click="onSel(item)"
        v-for="(item, rinx) in repList"
        :key="rinx"
        class="rep-item"
      >
        {{ item.title }}
        <i @click.stop="onDel(item)" class="delicon el-icon-error"></i>
      </div>
    </div>
    <div class="infobox" v-if="info">
      <div class="infotitle">访问信息</div>
      <div class="infoitem">名称：{{ info.nickname }}</div>
      <div class="infoitem">账号：{{ info.username }}</div>
      <div class="infoitem">IP：{{ info.ip }}</div>
      <div class="infoitem">地区：{{ info.ip_cityname }}</div>
    </div>

    <div class="rpborder">
      
    </div>

    <el-dialog
      title="新增"
      @close="cancel"
      :visible.sync="newtxt"
      :modal="false"
      width="420px"
      custom-class="group"
    >
      <div class="selectcc-content">
        <el-form>
          <el-form-item label="内容">
            <el-input v-model="title"></el-input>
          </el-form-item>
          <!-- <el-form-item label="内容">
            <el-input v-model="content"></el-input>
          </el-form-item> -->
        </el-form>
        <div style="text-align: center">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="confirm" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { repmsglist, repmsgadd, repmsgdel, fetchInfo,chatData  } from "@/api";

export default {
  data() {
    return {
      newtxt: false,
      title: "",
      content: "",
      info: null,
      repList: [
        // { text: "你好啊" },
        // { text: "有什么问题" },
        // { text: "333" },
        // { text: "444" },
        // { text: "哈哈哈" },
      ],
    };
  },
  watch: {},
  mounted() {
    console.log("::::信息更新")
    this.getData();
  },
  activated() {
    console.log("::::信息更新")
    this.getData();
  },
  computed: {
    ...mapGetters(["selectedChat"]),
    ...mapState(["user"]),
  },
  destroyed() {},
  methods: {
    getData() {
      console.log("查询数据ip")
      let web_id = localStorage.getItem("web_id");
      repmsglist({
        web_id: web_id,
      }).then((res) => {
        this.repList = res.data;
      });
      const l = this.selectedChat.list_id;
      console.log(l)
      chatData({
        list_id: l,
        time: 0,
        is_up: 1,
        _token: localStorage.getItem("token"),
        _agent_id: 1,
      }).then((res) => {
        fetchInfo({
          user_id: res.data.obj_id,
          in: 0,
        }).then((res) => {
          this.info = {
            ...res.data,
          };
        });
      });
    },
    onAdd() {
      this.title = "";
      this.content = "";
      this.newtxt = true;
    },
    cancel() {
      this.title = "";
      this.content = "";
      this.newtxt = false;
    },
    confirm() {
      if (!this.title) {
        this.$message.error("请输入标题");
        return;
      }
      // if (!this.content) {
      //   this.$message.error("请输入内容");
      //   return;
      // }

      let web_id = localStorage.getItem("web_id");
      const parmams = {
        web_id: web_id,
        title: this.title,
        content: this.content,
      };
      repmsgadd(parmams).then((res) => {
        if (res == 1) {
          this.$message.success("添加成功");
          this.getData();
          this.cancel();
        }
      });
    },
    onDel(e) {
      let web_id = localStorage.getItem("web_id");
      const parmams = {
        web_id: web_id,
        id: e.id,
      };
      repmsgdel(parmams).then((res) => {
        if (res == 1) {
          this.$message.success("删除成功");
          this.getData();
        }
      });
    },
    onSel(e) {
      this.$emit("sel", e.title);
    },
  },
};
</script>

<style lang="stylus" scoped>
.rep-warp {
  position: absolute;
  right: 0;
  bottom: 11px;
  width: 300px;
  padding: 20px 0px;
  top: 0px;
  z-index: 9999;
  border-left: 1px solid rgb(231, 231, 231);
  padding-top: 0;
}

.replist {
  overflow: auto;
  height: calc(100% - 146px);
  height: calc(100% - 171px);
  height: calc(100% - 199px);
  padding: 0 10px;
}

.rep-item {
  cursor: pointer;
  padding: 12px 4px;
  position: relative;
  background-color: #DBE3F0;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 14px;
}

// .rep-item:hover {
// background-color: #cccccc;
// }
.selectcc-content {
  height: 180px;
  padding: 10px 20px;
}

.delicon {
  position: absolute;
  color: red;
  right: 1px;
  top: 1px;
  font-size: 20px;
}

.infotitle {
  height: 30px;
  line-height: 30px;
  border-left: 3px solid #65a6d8;
  border-top: 1px solid #e7e7e7;
  padding-left: 8px;
  margin-bottom: 10px;
}

.infoitem {
  padding: 6px 10px;
}

.infobox {
  overflow: auto;
  height: 153px;
}
.rpborder{
  background: #e7e7e7;
  height: 200px;
  width: 1px;
  position: absolute;
  top: 100%;
  left: -1px;
}
</style>
