<template>
  <div v-if="selectedChat" class="message" style="position: relative">
    <replist @sel="selText"   :key="Date.now()"></replist>
    <header class="header" style="padding-top: 18px">
      <div class="friendname">
        <div style="display: flex; align-items: center">
          {{ selectedChat.user.name }}
          <el-button
            type="text"
            @click="onAdd"
            style="margin-left: 10px; margin-top: 2px"
            >分组</el-button
          >
          <el-button
            type="text"
            @click="changeRemarkNew"
            style="margin-left: 10px; margin-top: 2px"
            >备注</el-button
          >
          
          <span v-if="selectedChat.type == 1"
            >({{ groupDetail.member.length }})</span
          >
        </div>
        <!-- <span v-if="selectedChat.type == 0">({{ user.online === 0?'离线':'在线' }}) {{ user.ip }} {{ user.region }}</span> -->
      </div>
      <div
        class="header-option"
        @click="handleGroup"
        v-if="selectedChat.type == 1"
      >
        群管理
      </div>
      <!--  <div
        class="header-option"
        v-if="selectedChat.type == 1"
        @click="handleInvite"
      >邀请群成员</div> -->
    </header>
    <div v-show="load" ref="list" class="message-wrapper">
      <div
        class="notice"
        v-if="selectedChat.type == 1 && groupDetail && groupDetail.group.notice"
      >
        <p>{{ groupDetail.group.notice }}</p>
      </div>
      <ul
        v-if="
          selectedChat.messages &&
          selectedChat.messages[0] &&
          !selectedChat.messages[0].firstShow
        "
        :class="{
          mt: selectedChat.type == 1 && groupDetail && groupDetail.group.notice,
        }"
      >
        <li
          v-for="(item, index) in selectedChat.messages"
          :key="item.list_id + index"
          class="message-item"
        >
          <template
            v-if="
              (index == 0 && !selectedChat.messages[0].firstShow) || index != 0
            "
          >
            <template v-if="item.type != 1">
              <div
                v-if="
                  item.type == 0 &&
                  (index == 0 ||
                    item.date - selectedChat.messages[index - 1].date >
                      180 * 1000)
                "
                class="time"
              >
                <span>{{ item.date | time }}</span>
              </div>
              <div class="main" :class="{ self: item.self }">
                <el-popover
                  v-if="!item.self"
                  placement="right"
                  width="300"
                  trigger="click"
                  @show="showInfoBanner(item)"
                  @hide="showHide"
                >
                  <div class="friendInfo">
                    <div v-if="info" class="esInfo">
                      <div class="left">
                        <div class="people">
                          <div class="nickname">{{ info.nickname }}</div>
                          <div
                            :class="[
                              info.sex === 1 ? 'gender-male' : 'gender-female',
                            ]"
                          ></div>
                        </div>
                        <div class="signature">{{ info.signature }}</div>
                      </div>
                      <div class="right">
                        <img
                          class="avatar"
                          width="60"
                          height="60"
                          :src="photoUrl + info.photo"
                        />
                      </div>
                    </div>
                    <div v-if="info" class="detInfo">
                      <div class="remark">
                        <span>备&nbsp&nbsp&nbsp注</span>
                        <span
                          title="点击修改备注"
                          style="cursor: pointer"
                          @click="changeRemark(info, selectedChat.user.name)"
                          >{{ selectedChat.user.name }}
                        </span>
                      </div>
                      <!-- <div class="area">
                        <span>来&nbsp&nbsp&nbsp源</span
                        ><span>{{ info.from }}</span>
                      </div> -->
                      <div class="list_id">
                        <span>微信号</span><span>{{ info.username }}</span>
                      </div>
                    </div>
                    <div
                      v-if="info"
                      title="分享名片"
                      class="tip el-icon-position"
                      @click="handleShare(item)"
                    ></div>
                  </div>
                  <img
                    slot="reference"
                    class="avatar"
                    width="36"
                    height="36"
                    :src="
                      item.self
                        ? user.img
                        : item.user_info.face
                        ? photoUrl + item.user_info.face
                        : selectedChat.user.img
                    "
                  />
                </el-popover>
                <img
                  v-else
                  class="avatar"
                  width="36"
                  height="36"
                  :src="item.self ? user.img : selectedChat.user.img"
                />
                <div
                  class="content"
                  :class="
                    item.msgItem_type == 8 || item.msgItem_type == 10
                      ? 'card'
                      : ''
                  "
                  @contextmenu.prevent="(e) => handleOption(e, item)"
                >
                  <div
                    v-if="item.msgItem_type == 0"
                    class="text"
                    v-html="replaceFace(item.content.text)"
                  ></div>
                  <template v-if="item.msgItem_type == 2">
                    <video
                      v-if="item.content.url.indexOf('mp4') != -1"
                      style="width: 310px"
                      ref="veo"
                      :src="
                        chatUrl + selectedChat.list_id + '/' + item.content.url
                      "
                      :autoplay="false"
                      :controls="true"
                    >
                      您的浏览器不支持video标签。
                    </video>
                    <el-image
                      v-else
                      style="width: 200px"
                      :src="
                        chatUrl + selectedChat.list_id + '/' + item.content.url
                      "
                      :preview-src-list="[
                        chatUrl + selectedChat.list_id + '/' + item.content.url,
                      ]"
                    >
                    </el-image>
                  </template>
                  <template v-if="item.msgItem_type == 3">
                    <video
                      v-if="item.content.url.indexOf('mp4') != -1"
                      style="width: 310px"
                      ref="veo"
                      :src="
                        chatUrl + selectedChat.list_id + '/' + item.content.url
                      "
                      :autoplay="false"
                      :controls="true"
                    >
                      您的浏览器不支持video标签。
                    </video>
                    <el-image
                      v-else
                      style="width: 200px"
                      :src="
                        chatUrl + selectedChat.list_id + '/' + item.content.url
                      "
                      :preview-src-list="[
                        chatUrl + selectedChat.list_id + '/' + item.content.url,
                      ]"
                    >
                    </el-image>
                  </template>
                  <div
                    v-if="item.msgItem_type == 8"
                    class="send-card"
                    @click="cardInfo(item)"
                  >
                    <div class="send-card-content">
                      <img
                        width="42"
                        height="42"
                        :src="photoUrl + item.content.face"
                        alt=""
                      />
                      <span style="margin-left: 12px">{{
                        item.content.nickname
                      }}</span>
                    </div>
                    <div class="send-card-content-text">
                      <span>个人名片</span>
                    </div>
                  </div>
                  <div
                    v-if="item.msgItem_type == 10"
                    class="send-position"
                    @click="viewPosition(item)"
                  >
                    <div class="send-position-content">
                      <span>{{ item.content.text }}</span>
                    </div>
                    <div class="send-position-content-text">
                      <span>点击查看</span>
                    </div>
                  </div>
                  <div v-if="item.msgItem_type == 5" class="content">
                    收到红包，请在手机端打开
                  </div>
                  <div
                    v-if="
                      item.msgItem_type == 6 ||
                      item.msgItem_type == 7 ||
                      item.msgItem_type == 9
                    "
                    class="content"
                  >
                    {{ item.content.text }}
                  </div>
                  <!-- <div
                    v-if="item.self && showTip"
                    class="read-state"
                  >{{ item.read ? '已读': '未读' }}</div> -->
                </div>
              </div>
            </template>
            <div v-if="item.type == 1">
              <div class="time" v-if="item.content.text">
                <span>{{ item.content.text }}</span>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div v-show="menuVisible" id="contextmenu-msg" class="contextmenu-msg_menu">
      <div
        v-if="currentRow.self"
        class="contextmenu-msg__item"
        @click="handleSetting(1)"
      >
        撤回
      </div>
      <div class="contextmenu-msg__item" @click="handleSetting(2)">收藏</div>
      <div class="contextmenu-msg__item" @click="handleSetting(3)">转发</div>
    </div>
    <selectUser
      ref="selectUser"
      title="消息转发"
      tip="分别转发给"
      @handleOk="handleOk"
    ></selectUser>
    <selectUser
      ref="selectInvite"
      title="邀请新成员"
      tip=""
      :default="defaultList"
      @handleOk="handleInvited"
    ></selectUser>
    <selectUser
      ref="handleShare"
      title="分享名片"
      tip="分别转发给"
      :multiple="false"
      @handleOk="handleShared"
    ></selectUser>
    <groupInfo ref="groupInfo"> </groupInfo>
    <el-dialog
      title="信息"
      :visible.sync="showInfo"
      width="500px"
      :close-on-click-modal="false"
    >
      <div class="friendInfo">
        <div v-if="info" class="esInfo">
          <div class="left">
            <div class="people">
              <div class="nickname">{{ info.nickname }}</div>
              <div
                :class="[info.sex === 1 ? 'gender-male' : 'gender-female']"
              ></div>
            </div>
            <div class="signature">{{ info.signature }}</div>
          </div>
          <div class="right">
            <img
              class="avatar"
              width="60"
              height="60"
              :src="photoUrl + info.photo"
            />
          </div>
        </div>
        <div v-if="info" class="detInfo">
          <div class="remark">
            <span>备&nbsp&nbsp&nbsp注</span>
            <span
              title="点击修改备注"
              style="cursor:pointer"
              @click="changeRemark(info, selectedChat.user.name)"
            >{{ selectedChat.user.name }}
            </span>
          </div>
          <div class="list_id">
            <span>用户名</span><span>{{ info.username }}</span>
          </div>
          <div class="area">
            <span>签&nbsp&nbsp&nbsp名</span><span>{{ info.doodling }}</span>
          </div>
          <div class="list_id">
            <span>是否好友</span
            ><span>{{ info.is_friend == 1 ? "是" : "否" }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showInfo = false">取 消</el-button>
        <el-button
          v-if="info && info.is_friend != 1"
          type="primary"
          @click="add(info)"
          >添加好友</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="查看位置"
      :visible.sync="showMapInfo"
      width="500"
      append-to-body
    >
      <look-map-info :timeStamp="Date.parse(new Date())" :mapData="mapInfo" />
    </el-dialog>
    <!-- 分组 -->
    <el-dialog
      title="分组"
      :visible.sync="dialogGroupVisible"
      width="500px"
      :close-on-click-modal="true"
    >
      <el-form :model="formData">
        <el-form-item label="请选择分组类型" label-width="60">
          <el-radio v-model="formData.type" label="1">我的分组</el-radio>
          <el-radio v-model="formData.type" label="2">新建分组</el-radio>
        </el-form-item>
        <el-form-item
          v-if="formData.type == 1"
          label="请选择我的分组"
          label-width="60"
        >
          <el-select
            style="width: 200px"
            v-model="formData.groupname"
            placeholder="请选择我的分组"
           
          >
            <el-option
              v-for="item in groupDic"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.type == 2"
          label="请输入分组名称"
          label-width="60"
        >
          <el-input
            style="width: 200px"
            v-model="formData.groupname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogGroupVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveGroup">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchInfo,
  withdraw,
  setRemarks,
  sendCard,
  saveStore,
  textMsgs,
  addChat,
  friendAdd,
} from "@/api";
import { mapGetters, mapState, mapActions } from "vuex";
import selectUser from "@/components/selectUser";
import groupInfo from "@/components/group/groupInfo.vue";
import lookMapInfo from "@/components/lookMapInfo.vue";
import replist from "@/components/replist/replist";

// 高德地图
import AmapVue from "@amap/amap-vue";
import { adduserGroup,setuserGroup } from "@/api";
const { photoUrl, chatUrl } = window.__gconf;
export default {
  components: {
    selectUser,
    groupInfo,
    lookMapInfo,
    replist,
  },
  filters: {
    // 将日期过滤为 hour:minutes
    time(timestamp) {
      timestamp = new Date(timestamp).valueOf() / 1000;
      let curTimestamp = parseInt(new Date().getTime() / 1000), //当前时间戳
        timestampDiff = curTimestamp - timestamp, // 参数时间戳与当前时间戳相差秒数
        curDate = new Date(curTimestamp * 1000), // 当前时间日期对象
        tmDate = new Date(timestamp * 1000), // 参数时间戳转换成的日期对象
        Y = tmDate.getFullYear(),
        m = tmDate.getMonth() + 1,
        d = tmDate.getDate(),
        H = tmDate.getHours(),
        i = tmDate.getMinutes(),
        s = tmDate.getSeconds();
      if (timestampDiff < 60) {
        // 一分钟以内
        return "刚刚";
      } else if (timestampDiff < 3600) {
        // 一小时前之内
        return Math.floor(timestampDiff / 60) + "分钟前";
      } else if (
        curDate.getFullYear() == Y &&
        curDate.getMonth() + 1 == m &&
        curDate.getDate() == d
      ) {
        return (
          "今天 " +
          ((String(H).length == 1 ? "0" : "") + H) +
          ":" +
          ((String(i).length == 1 ? "0" : "") + i)
        );
      } else {
        var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (
          newDate.getFullYear() == Y &&
          newDate.getMonth() + 1 == m &&
          newDate.getDate() == d
        ) {
          return (
            "昨天 " +
            ((String(H).length == 1 ? "0" : "") + H) +
            ":" +
            ((String(i).length == 1 ? "0" : "") + i)
          );
        } else if (curDate.getFullYear() == Y) {
          return (
            (String(m).length == 1 ? "0" : "") +
            m +
            "月" +
            ((String(d).length == 1 ? "0" : "") + d) +
            "日 " +
            ((String(H).length == 1 ? "0" : "") + H) +
            ":" +
            ((String(i).length == 1 ? "0" : "") + i)
          );
        } else {
          return (
            Y +
            "年" +
            ((String(m).length == 1 ? "0" : "") + m) +
            "月" +
            ((String(d).length == 1 ? "0" : "") + d) +
            "日 " +
            ((String(H).length == 1 ? "0" : "") + H) +
            ":" +
            ((String(i).length == 1 ? "0" : "") + i)
          );
        }
      }
    },
  },
  data() {
    return {
      showRep: false,
      groupDic: [],
      formData: {
        name: "",
        type: "1",
        groupname: "",
      },
      dialogGroupVisible: false,
      photoUrl: photoUrl,
      chatUrl: chatUrl,
      showTip: false,
      info: null,
      menuVisible: false,
      currentRow: {},
      load: false,
      isFirst: false,
      defaultList: [],
      showInfo: false,
      shareItem: {},
      // 经纬度信息
      mapInfo: "",
      // 是否查看信息地图
      showMapInfo: false,
    };
  },
  computed: {
    ...mapGetters(["selectedChat", "messages"]),
    ...mapState([
      "user",
      "emojis",
      "groupChat",
      "selectId",
      "groupList",
      "groupDetail",
    ]),
  },
  watch: {
    // 发送信息后,让信息滚动到最下面
    "selectedChat.messages": {
      handler: function (a) {
        if (!this.isFirst) {
          this.load = false;
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.list
              ? (this.$refs.list.scrollTop = this.$refs.list.scrollHeight)
              : "";

            this.load = true;
            this.isFirst = true;
          }, 0);
        });
      },
      deep: true,
    },
    "selectedChat.chat_id": {
      handler: function (a) {
        console.log("selectedChat", this.selectedChat);
        this.showTip = true;
      },
    },
    groupList: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.groupDic = newVal.map((item) => ({
            name: item.group.name,
            value: item.group.id,
          }));
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.fetchGroupList();

    //  在页面加载时让信息滚动到最下面
    if (this.$refs.list)
      setTimeout(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
      }, 0);
  },
  destroyed() {
    this.$store.dispatch("clearIntervalTimer");
  },
  methods: {
    ...mapActions(["selectFriend2", "fetchCharList", "fetchGroupList", "fetchFriendList"]),
    onAdd() {
      this.formData={
        name:"",
        type: "1",
        groupname: "",
      }
      this.dialogGroupVisible = true;
      this.fetchGroupList();
    },
    changeRemarkNew(item) {
      let that = this
      console.log(that.selectedChat.user)
      this.$prompt("请输入备注信息", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputValue: that.selectedChat.user.name,
        inputErrorMessage: "备注信息不能为空",
      })
        .then(({ value }) => {
          setRemarks({
            content: value,
            user_id: that.selectedChat.user.userId,
          }).then((res) => {
            this.$message.success("备注修改成功");
            that.fetchFriendList();
            that.fetchCharList(that.selectId || "");
            that.fetchGroupList()
          });
        })
        .catch(() => {});
      console.log(item);
    },
    handleGroupChange() {
      console.log('1');
      this.fetchGroupList();
    },
    async handleSaveGroup() {
      console.log(this.formData);
      console.log(this.selectedChat.user)
      if(this.formData.type == 2){
        await setuserGroup({
        
          groupname: this.formData.groupname,
        _token: localStorage.getItem("token"),
        _agent_id: 1,
      }).then((res) => {
        this.dialogGroupVisible = false;
        if(res.err == 0){
          this.$message.success("新建分组成功");
        }else{
          this.$message.error(res.msg);
        }
        });

      }else{
        await adduserGroup({
          userids: this.selectedChat.user.userId,
          groupname: this.formData.groupname,
        _token: localStorage.getItem("token"),
        _agent_id: 1,
      }).then((res) => {
        this.dialogGroupVisible = false;
        if(res.err == 0){
          this.$message.success("添加成功");
        }else{
          this.$message.error(res.msg);
        }
        });
      }
    },
    selText(e) {
      this.$emit("selText", e);
    },
    cardInfo(item) {
      console.log(JSON.stringify(item));
      fetchInfo({
        user_id: item.content.user_id,
        in: 0,
      }).then((res) => {
        this.info = {
          ...res.data,
        };
        this.showInfo = true;
      });
    },
    viewPosition(item) {
      var lat = item.content.position.lat;
      var lon = item.content.position.lon;
      this.mapInfo = lon + "," + lat;
      this.showMapInfo = true;
    },
    changeRemark(item, remark) {
      console.log(item)
      this.$prompt("请输入备注信息", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputValue: remark,
        inputErrorMessage: "备注信息不能为空",
      }).then(({ value }) => {
        setRemarks({
          content: value,
          user_id: item.user_id,
        }).then((res) => {
          this.fetchCharList(this.selectId || "");
          // this.showInfoBanner(item)
          // this.fetchFriendList()
        });
      });
    },
    handleInvite() {
      this.$set(this, "defaultList", [
        ...this.selectedChat.groupImgs.map((item) => item.user_id),
      ]);
      console.log(this.defaultList);
      this.$refs.selectInvite.open(this.defaultList);
    },
    handleGroup() {
      this.$refs.groupInfo.open();
    },
    showInfoBanner(item) {
      fetchInfo({
        user_id: item.userId,
        in: 0,
      }).then((res) => {
        this.info = {
          ...res.data,
        };
      });
    },
    add(item) {
      this.$prompt("你需要发送验证申请,等对方通过", "朋友验证", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        friendAdd({
          user_id: item.user_id,
          is_type: 0,
          content: value,
        }).then((res) => {
          this.$message.success("申请成功");
          this.showInfo = false;
        });
      });
    },
    handleInvited(data) {
      const users = [];
      data.forEach((item) => {
        if (!this.defaultList.includes(item.user_id)) {
          users.push(item.user_id);
        }
      });
      addChat({
        users: JSON.stringify(users),
        list_id: this.selectedChat.list_id,
      }).then((res) => {
        this.fetchCharList(this.selectId || "");
      });
    },
    handleOk(data) {
      console.log(data);
      textMsgs({
        list_ids: data
          .filter((item) => item.list_id)
          .map((item) => {
            return item.list_id;
          })
          .join(),
        content_type: this.currentRow.msgItem_type,
        current_list_id: this.selectedChat.list_id,
        content: JSON.stringify(this.currentRow.content),
      }).then((res) => {
        this.fetchCharList(this.selectId || "");
      });
    },
    showHide() {
      setTimeout(() => {
        this.info = null;
      }, 300);
    },
    handleOption(event, item) {
      this.menuVisible = false; // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true; // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault(); //关闭浏览器右键默认事件
      this.currentRow = {
        ...item,
      };
      console.log(item);
      var menu = document.querySelector(".contextmenu-msg_menu");
      this.styleMenu(menu, event);
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false;
      document.removeEventListener("click", this.foo); // 关掉监听，
    },
    styleMenu(menu, event) {
      if (event.clientX > 1800) {
        menu.style.left = event.clientX - 100 + "px";
      } else {
        menu.style.left = event.clientX + 1 + "px";
      }
      document.addEventListener("click", this.foo); // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (event.clientY > 700) {
        menu.style.top = event.clientY - 30 + "px";
      } else {
        menu.style.top = event.clientY - 10 + "px";
      }
    },
    handleShare(item) {
      this.shareItem = item;
      this.$refs.handleShare.open();
    },
    handleShared(data) {
      // alert(JSON.stringify(data))
      sendCard({
        users: JSON.stringify([this.shareItem.userId + ""]),
        list_id: this.selectedChat.list_id,
      }).then((res) => {
        this.fetchCharList(this.selectId || "");
        this.$message.success("发送成功");
      });
    },
    handleSetting(type) {
      const data = {
        list_id: this.currentRow.list_id,
        type: this.currentRow.msg_type,
        msg_id: this.currentRow.msg_id,
      };
      let fetchUrl;
      if (type == 2) {
        fetchUrl = saveStore;
      } else if (type == 1) {
        fetchUrl = withdraw;
      } else {
        this.$refs.selectUser.open();
        return;
        // fetchUrl = deleteChat
      }
      fetchUrl(data).then((res) => {
        if (type == 1) {
          this.$store.dispatch("selectSession", this.selectId);
        } else if (type == 2) {
          if (res.msg == "收藏成功") {
            this.$message.success("收藏成功");
          } else {
            this.$message.warning(res.msg);
          }
        }
      });
    },
    //  在发送信息之后，将输入的内容中属于表情的部分替换成emoji图片标签
    //  再经过v-html 渲染成真正的图片
    replaceFace(con) {
      if (con && con.includes("[") && con.includes("]")) {
        var emojis = this.emojis;
        var newCon = "";
        con.split("]").forEach((item) => {
          if (item.lastIndexOf("[") > 0) {
            item.split("[").forEach((a) => {
              if (a != "") {
                for (var i = 0; i < emojis.length; i++) {
                  if (emojis[i].code.includes(a)) {
                    newCon +=
                      '<img src="static/emoji/' +
                      emojis[i].file +
                      '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />';
                    return;
                  } else if (i + 1 == emojis.length) {
                    newCon += a;
                  }
                }
              } else {
                newCon += "[";
              }
            });
          }
          if (item != "") {
            for (var i = 0; i < emojis.length; i++) {
              if (emojis[i].code.includes(item)) {
                newCon +=
                  '<img src="static/emoji/' +
                  emojis[i].file +
                  '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />';
                return;
              }
            }
          }
        });

        return newCon;
      }
      return con;
    },
  },
};
</script>

<style lang="stylus" scoped>
.mt
 margin-top 56px
.notice
  box-sizing border-box
  position absolute;
  width 100%;
  display flex
  justify-content center
  p
   background: #eee;
   padding: 15px;
   display: inline-block;
   border-radius: 5px;
   color: #333;
   font-size 12px
.contextmenu-msg__item
  display block
  line-height 34px
  text-align center
.contextmenu-msg__item:not(:last-child)
  border-bottom 1px solid rgba(0, 0, 0, 0.1)
.contextmenu-msg_menu
  cursor pointer
  position absolute
  background-color #fff
  width 100px
  /* height: 106px; */
  font-size 12px
  color #444040
  border-radius 4px
  -webkit-box-sizing border-box
  box-sizing border-box
  border-radius 3px
  border 1px solid rgba(0, 0, 0, 0.15)
  box-shadow 0 6px 12px rgba(0, 0, 0, 0.175)
  white-space nowrap
  z-index 1000
.contextmenu__item:hover
  cursor pointer
  background #eee
  border-color #eee
.message
  width 100%
  height calc(100% - 200px)
  background #f3f3f3
  .header
    height 60px
    padding 28px 0 0 15px
    box-sizing border-box
    border-bottom 1px solid #e7e7e7
    position relative
    &-option
      position absolute
      right 10px
      top 26px
      cursor pointer
      color #007AFF
    .friendname
      font-size 18px
  .message-wrapper
    position relative
    height calc(100% - 70px)
    padding 10px 15px
    min-width: 1024px
    box-sizing border-box
    overflow-y auto
    overflow-x hidden
    padding-right: 320px
    border-bottom 1px solid #e7e7e7
    .message-item
      margin-bottom 10px
    .group
      position absolute
      z-index 999
      background-color #e7e7e7
    .group li
      margin-right 10px
      display inline-block
    .group li img
      width 36px
      height 36px
    .message
      margin-bottom 15px
    .time
      width 100%
      font-size 12px
      margin 7px auto
      text-align center
      span
        display inline-block
        line-height 14px
        padding 4px 6px
        color #fff
        border-radius 3px
        background-color #dcdcdc
    .main
      .avatar
        float left
        margin-left 15px
        border-radius 3px
      .content
        display inline-block
        margin-left 10px
        position relative
        padding 6px 10px
        max-width 330px
        min-height 36px
        line-height 24px
        box-sizing border-box
        font-size 14px
        text-align left
        word-break break-all
        background-color #fff
        border-radius 4px
        &:before
          content ' '
          position absolute
          top 12px
          right 100%
          border 6px solid transparent
          border-right-color #fff
    .self
      text-align right
      .avatar
        float right
        margin 0 15px
      .content
        background-color #007AFF
        color #fff
        &.card
          background-color #fff
          padding:0;
          border 1px solid #eee
          &:before
            border-right-color transparent
            border-left-color #eee
        &:before
          right -11px
          vertical-align middle
          border-right-color transparent
          border-left-color #007AFF
        .read-state
          position absolute
          left -30px
          top 6px
          font-size 12px
          opacity 0.4
.friendInfo
  padding 0 30px
.esInfo
  display flex
  align-items center
  padding 20px 0 20px 0
  .left
    flex 1
    .people
      .nickname
        display inline-block
        font-size 20px
        margin-bottom 16px
      .gender-male, .gender-female
        display inline-block
        width 18px
        height 18px
        vertical-align top
        margin-top 2px
    .signature
      font-size 14px
      color rgba(153, 153, 153, 0.8)
  .right
    .avatar
      border-radius 3px
.tip
  font-size 24px
  float right
  cursor pointer
.detInfo
  padding 20px 0 10px
  border-top 1px solid #e7e7e7
  .remark, .area, .list_id
    font-size 14px
    margin-top 20px
    span
      font-size 14px
      color rgba(153, 153, 153, 0.8)
      margin-right 40px
      &:last-child
        margin-right 0
  .remark
    margin-top 0
</style>
<style lang="less" scoped>
.send-card {
  width: 200px;
  display: flex;
  min-height: 25px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  flex-direction: column;
  color: #333;
  cursor: pointer;
}

.send-card-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 7px;
  height: 100%;
  background: url(../../assets/images/card.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  border-bottom: 1px solid #e1e1e1;
  color: #fff;
}

.send-card-content text {
  margin-left: 10px;
  color: #fff;
}
.message .message-wrapper .main .content.card {
  padding: 0 !important;
}
.send-card-content-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 20upx;
  padding: 0px 10px;
  color: #999;
}
.send-position {
  width: 200px;
  display: flex;
  min-height: 25px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  flex-direction: column;
  color: #333;
  cursor: pointer;
}

.send-position-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 7px;
  height: 100%;
  background: url(../../assets/images/card.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  border-bottom: 1px solid #e1e1e1;
  color: #fff;
}

.send-position-content text {
  margin-left: 10px;
  color: #fff;
}
.message .message-wrapper .main .content.card {
  padding: 0 !important;
}
.send-position-content-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 20upx;
  padding: 0px 10px;
  color: #999;
}
</style>
