<template>
  <div id="app" v-if="!showLogin">
    <div
      v-if="showSide"
      class="sidebar"
    >
      <mycard></mycard>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
  <div id="appLogin" v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import mycard from './components/mycard/mycard'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/reset.css'

export default {
  name: 'App',
  components: {
    mycard
  },
  data () {
    return {
      showSide: false,
      showLogin: false
    }
  },
  watch: {
    $route (route) {
      this.showSide = route.meta && route.meta.showSide
      this.showLogin = route.meta && route.meta.showLogin
    }
  },
  mounted () {
    Notification.requestPermission()
  }
};
</script>

<style lang="less">
html,
body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  font-size: 16px;
  background-color: #eee;
  -webkit-font-smoothing: antialiased;
}
#app {
  width: 100%;
  height: 100%;
  background-color: #eee;
  display: flex;
}
#appLogin {
  width: 100%!important;
  height: 100%!important;
  background-color: #eee;
  display: flex;
}
#app > .main > .content {
  height: 100%;
  background-color: #F5F5F5;
  display: flex;
}
</style>
<style lang="stylus" scoped>
.sidebar
  width 100px
  background #2b2c2f
.main
  flex 1
</style>
